import React from "react"
import { Banner } from "./HeroBanner-types"
import "./generic-hero.module.scss"
import { useColorAreaSettings } from "hooks/useColorAreaSettings"
import { HeroContent } from "./HeroContent"

interface HeroProps {
  banner: Banner
}

export const GenericHero = ({ banner }: HeroProps) => {
  const { secondary_image, heading, sub_heading, buttons } = banner
  const { textBannerBackgroundColor } = useColorAreaSettings()

  const overrideTextBackgroundColor = textBannerBackgroundColor
    ? `${textBannerBackgroundColor}--backgroundColor`
    : ``

  return (
    <section className={`fr-hero-generic ${overrideTextBackgroundColor}`}>
      <div className="fr-container">
        <HeroContent
          heading={heading?.value}
          subHeading={sub_heading}
          buttons={buttons?.value}
          baseClassName={"generic-banner-content"}
          secondaryImage={secondary_image?.value[0]}
        />
      </div>
    </section>
  )
}
