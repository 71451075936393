import React, { ReactNode } from "react"
import { RichTextContent } from "_types/RichText"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import { ButtonParser } from "components/button/ButtonParser"
import { ButtonBuilder } from "_types/Button"
import { ImageBuilder } from "_types/AssetsImage"
import { Image } from "components/image/Image"

interface HeroContentProps {
  eyebrowText?: string
  heading?: string
  subHeading?: RichTextContent
  buttons?: ButtonBuilder[]
  secondaryImage?: ImageBuilder
  children?: ReactNode
  baseClassName:
    | "hero-banner-content"
    | "ppc-banner-content"
    | "generic-banner-content"
}

export const HeroContent = ({
  eyebrowText,
  heading,
  subHeading,
  buttons,
  secondaryImage,
  baseClassName,
  children,
}: HeroContentProps) => {
  return (
    <div
      className={
        secondaryImage ? `${baseClassName}--with-image` : baseClassName
      }
      style={
        { "--image-width": `${secondaryImage?.width || 0}px` } as Record<
          string,
          string
        >
      }
    >
      {eyebrowText && <p className={`eyebrow-text`}>{eyebrowText}</p>}
      <div className={`${baseClassName}__content-wrapper`}>
        {heading && (
          <h1
            className={
              secondaryImage
                ? `${baseClassName}__heading--with-image`
                : `${baseClassName}__heading`
            }
          >
            {heading}
          </h1>
        )}
        {subHeading && subHeading.value !== "<p><br></p>" && (
          <RichTextElement value={subHeading.value} />
        )}
        {buttons && buttons?.length > 0 && (
          <div className={`${baseClassName}__buttons`}>
            <ButtonParser
              buttons={buttons}
              primaryColorVariant="outline-secondary"
              secondaryColorVariant="solid-primary"
            />
          </div>
        )}
      </div>
      {children}
      {secondaryImage && (
        <Image
          image={secondaryImage}
          className="secondary-image"
          objectFit="cover"
        />
      )}
    </div>
  )
}
