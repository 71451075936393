import React from "react"
import { Breadcrumb as GatsbyBreadcrumb } from "gatsby-plugin-breadcrumb"
import "./breadcrumbs.module.scss"

interface BreadcrumbProps
  extends React.ComponentProps<typeof GatsbyBreadcrumb> {
  hasTransparentBackgroundColor?: boolean
}

export const Breadcrumb = ({
  hasTransparentBackgroundColor = false,
  ...GatsbyBreadcrumbProps
}: BreadcrumbProps) => {
  const transparentBackgroundColorModifier = hasTransparentBackgroundColor
    ? "breadcrumb-container--transparent-background-color"
    : ""
  return (
    <div
      className={`breadcrumb-container ${transparentBackgroundColorModifier}`}
    >
      <GatsbyBreadcrumb {...GatsbyBreadcrumbProps} />
    </div>
  )
}
